.wrapper {
  display: flex;
  position: relative;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
  header,
  footer {
    flex-shrink: 0;
  }
  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-width: 100vw;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
