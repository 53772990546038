.root {
  display: flex;

  & > * {
    margin-right: 14px;

    &:last-child {
      margin-right: 0;
    }
  }
}
