@use '../../styles/variables.scss' as vars;

.root {
  border-top: 1px solid vars.$color-border;
  position: relative;

  tr {
    border-bottom: 1px solid vars.$color-border;
    cursor: pointer;
    transition: all vars.$trans-300;

    &:last-child {
      border-bottom: none;
    }

    &:hover:not(:global(.active)),
    &:focus:not(:global(.active)) {
      background-color: rgba(vars.$color-bg-primary, 0.25) !important;
    }
  }
}

:global(.active) {
  background-color: rgba(vars.$color-focus, 1) !important;
  color: vars.$color-default-white;
}
