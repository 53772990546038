@use '../../styles/variables.scss' as vars;

.slide {
  width: calc(100% - 20px);
  height: 180px;
  background-color: vars.$color-primary;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding: 10px;
  position: relative;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    z-index: -1;
    transition: all vars.$trans-300;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: vars.$color-border;
    z-index: -2;
  }

  &.emptySlide {
    background-color: vars.$color-border;
    pointer-events: none;
  }
}

:global(.swiper-slide) {
  height: 180px;
}

:global(.swiper-slide:nth-child(2n + 1)) {
  margin-bottom: 20px;
  .slide::before,
  .slide::after {
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

// :global(.swiper-slide:nth-child(2n + 1)):has(.a3middle) {
//   .slide::before,
//   .slide::after {
//     border-top-left-radius: 0;
//     border-top-right-radius: 0;
//   }
// }

:global(.swiper-slide:nth-child(2n)) {
  margin-bottom: 40px;
  .slide::before,
  .slide::after {
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

// :global(.swiper-slide:nth-child(2n)):has(.a3middle) {
//   margin-bottom: 20px;
//   .slide::before,
//   .slide::after {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// }

.slideState {
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
}

.active::after {
  background-color: vars.$color-focus;
}

.previewImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  transition: all vars.$trans-300;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  background-color: rgba(vars.$color-default-black, 0.2);

  &.empty svg {
    transform: rotate(35deg);
  }
}

.hidden {
  display: none;
}
