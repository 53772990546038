@use '../../../styles/variables.scss' as vars;

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(171 171 145);
  border-color: rgb(163 163 152);
  padding: 10px 24px;

  & > * {
    margin-right: 14px;

    &:last-child {
      margin-right: auto;
    }
  }
}

.stats {
  display: flex;
  align-items: center;
}

.error {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: vars.$color-danger;
}
