.root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  h1 {
    font-size: 40px;
    line-height: 1.1;
    margin: 0;
  }
}

.logo {
  height: 80px;
  margin-right: 20px;
  flex: 0 0 auto;
}

.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCard {
  width: 100%;
  max-width: 550px;
}
