@use '../../styles/variables.scss' as vars;

.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  background-color: vars.$color-default-white;
  border-right: 1px solid vars.$color-border;
  z-index: 5;
  transform: translateX(-100%);
  transition: all vars.$trans-300;
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 100vh;

  &Opened {
    transform: translateX(0);
    box-shadow: vars.$box-shadow;
  }
}

.closeBtn {
  margin-left: auto;
}

.links {
  padding: 14px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.menuLink {
  padding: 4px 16px;
  font-weight: 700;
  border-radius: vars.$border-radius-xs;
  display: flex;
  align-items: center;
  transition: all vars.$trans-300;

  &:hover,
  &:focus {
    color: vars.$color-focus;
  }
}

.activeLink {
  color: vars.$color-focus;
  pointer-events: none;
}

.icon {
  font-size: 22px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
