@use '../../styles/variables.scss' as vars;

.header {
  background-color: vars.$color-default-white;
  border-bottom: 1px solid vars.$color-border;
  box-shadow: vars.$box-shadow;
  z-index: 10;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.backBtn {
  svg {
    transform: rotate(180deg);
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-weight: 500;
  padding-top: 3px;
  margin-bottom: -10px;

  & > * {
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.rightInfo {
  flex: 0 0 auto;
}

.headerInfo {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.headerBtn {
  font-size: 20px;
  margin-left: 30px;
}

.headerInfoBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
}
