@use '../../styles/variables.scss' as vars;

.root {
  background-color: vars.$color-primary;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 1;

  th {
    text-align: start;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    color: vars.$color-deep-black;
  }
}

.resizer {
  position: absolute;
  right: 0;
  top: 22px;
  height: 16px;
  width: 7px;
  border-left: 2px solid vars.$color-primary;
  border-right: 2px solid vars.$color-primary;
  background: vars.$color-default-black;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;

  &:hover {
    opacity: 0.6;
  }
}

.resizer.isResizing {
  background: vars.$color-focus;
  opacity: 0.6;
}

.sortable {
  cursor: pointer;
  transition: vars.$trans-300;

  &:hover,
  &:focus {
    color: vars.$color-focus;
  }
}

.sortingIcon {
  margin-left: 6px;

  &.asc svg :global(#asc) {
    color: vars.$color-focus;
  }

  &.desc svg :global(#desc) {
    color: vars.$color-focus;
  }
}
