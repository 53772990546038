.label {
  margin: 0;
  margin-bottom: 12px;
}

.value {
  margin: 0;
  margin-bottom: 24px;
  font-weight: 700;
}
