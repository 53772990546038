@use '../../styles/variables.scss' as vars;

.root {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  :global(.ps__rail-y) {
    opacity: 1;
  }

  :global(.ps__thumb-y) {
    background-color: #aaa;
    width: 11px;
  }
}

.list {
  padding: 0 10px;
  margin: 0;
  position: relative;
  flex-grow: 1;
  list-style: none;
  width: 100%;
}

.listButton {
  width: 100%;
  transition: all vars.$trans-300;
  border-radius: vars.$border-radius-xs;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;

  &.viewed button {
    font-weight: 400;
  }

  &:hover,
  &:focus {
    background-color: vars.$color-primary;
  }

  button {
    font-weight: 600;
    width: 100%;
    border: none;
    background-color: transparent;
    min-height: 38px;
    cursor: pointer;
    padding: 0;
    color: vars.$color-default-black;
  }
}

.listButtonActive {
  position: relative;
  background-color: vars.$color-primary !important;

  &::before {
    content: '';
    position: absolute;
    left: -57px;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: vars.$color-focus;
    border-radius: 0 5px 5px 0;
  }
}

.dublicate {
  background-color: vars.$color-low-bg;

  button {
    color: vars.$color-danger;
  }
}

.extraCount {
  color: vars.$color-danger;
  font-size: 12px;
  position: absolute;
  left: 6px;
  user-select: none;
}

.touchIndicator {
  position: absolute;
  right: 6px;
}
