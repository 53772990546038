@use '../../styles/variables.scss' as vars;
.root {
  display: flex;
  flex-direction: column;
  position: relative;

  .button {
    width: 47px;
    height: 47px;
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
}

.label {
  margin-left: 20px;
  margin-bottom: 6px;
  font-size: 16px;
}

.vertical {
  flex-direction: row;
  align-items: center;

  .label {
    margin-bottom: 0;
    margin-right: 6px;
  }
}

.small {
  input {
    height: 40px;
    padding: 10px 20px;
  }

  .button {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 2px;
    top: 2px;
  }
}

.input {
  height: 51px;
  border: 1px solid vars.$color-border;
  border-radius: vars.$border-radius-m;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 20px;
  outline: none;
  cursor: pointer;
  transition: all vars.$trans-300;
  color: vars.$color-default-black;

  &::placeholder {
    color: vars.$color-default-black;
  }

  &:hover,
  &:focus {
    border-color: vars.$color-focus;
  }
}

.error {
  input {
    border-color: vars.$color-danger;
    color: vars.$color-danger;
  }
}

.errorMessage {
  color: vars.$color-danger;
  position: absolute;
  left: 20px;
  bottom: 0;
  font-size: 12px;
}

.withErrorSpace {
  padding-bottom: 16px;
}

.withButton {
  input {
    padding-right: 57px;
  }
}
