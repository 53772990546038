@use '../../styles/variables.scss' as vars;

.root {
  min-width: 170px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: vars.$border-radius-l;
  border: none;
  background-color: vars.$color-focus;
  color: vars.$color-default-white;
  font-size: 14px;
  font-weight: 400;
  transition: all vars.$trans-300;

  &:hover,
  &:focus {
    background-color: darken(vars.$color-focus, 20);
    color: vars.$color-default-white;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &.small {
    min-width: auto;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: vars.$border-radius-xs;
  }
}

.round {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: auto;
  padding: 8px;

  &.big {
    width: 44px;
    height: 44px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.toolbar {
  border-radius: 0;
  width: 46px;
  height: 46px;
  padding: 4px;
  font-size: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: auto;
  padding: 4px;
  box-shadow: 0 0 0 1px vars.$color-border;

  .icon {
    margin-right: 0;
  }

  &.selected {
    background-color: darken(vars.$color-focus, 20);
    color: vars.$color-default-white;
  }
}

.toolbarText {
  border-radius: 0;
  height: 46px;
  padding: 4px;
  min-width: auto;
  padding: 10px;
  box-shadow: 0 0 0 1px vars.$color-border;
}

.toolbar:disabled,
.toolbarText:disabled {
  position: relative;
  opacity: 1;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(vars.$color-default-black, 0.3);
  }
}

.primary {
  background-color: vars.$color-focus;
  color: vars.$color-default-white;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: darken(vars.$color-focus, 20);
  }
}

.outlined {
  background-color: vars.$color-default-white;
  color: vars.$color-focus;
  border: 1px solid vars.$color-focus;
}

.success {
  color: vars.$color-default-white;
  background-color: darken(vars.$color-success, 10);

  &:hover,
  &:focus {
    background-color: darken(vars.$color-success, 20);
  }
}

.danger {
  background-color: vars.$color-danger;
  color: vars.$color-default-white;

  &:hover,
  &:focus {
    background-color: darken(vars.$color-danger, 20);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 6px;
}

.plain {
  border: none;
  background-color: transparent;
  padding: 0;
  min-width: auto;
  height: auto;
  border-radius: 0;
  color: vars.$color-default-black;

  &:hover,
  &:focus {
    background-color: transparent;
    color: vars.$color-focus;
  }
}
