@use '../../styles/variables.scss' as vars;

.root {
  background-color: vars.$color-focus;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid vars.$color-border;
  box-shadow: vars.$box-shadow;
  min-height: fit-content;
}
