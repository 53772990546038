@use '../../styles/variables.scss' as vars;

.btnGroup {
  width: 50%;
  display: flex;
}

.btnWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.insertBtnGroup {
  display: flex;
}

.commonActions {
  margin-left: auto;
  display: flex;
}
