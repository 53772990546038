@use '../../styles/variables.scss' as vars;

.root {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.active.danger {
  background-color: vars.$color-danger;
}

.active.success {
  background-color: vars.$color-success;
}
