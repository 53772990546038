@use '../../styles/variables.scss'as vars;

.root {
  width        : 18px;
  height       : 18px;
  border-radius: 50%;
  position     : relative;

  &:hover {
    .hint {
      display: block;
    }
  }
}

.green {
  background-color: vars.$color-success;
}

.red {
  background-color: vars.$color-danger;
}

.grey {
  background-color: vars.$color-border-dark;
}

.hint {
  display      : none;
  position     : absolute;
  left         : 30px;
  color        : vars.$color-default-white;
  padding      : 4px 6px;
  font-size    : 14px;
  line-height  : 1;
  font-weight  : 500;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
}