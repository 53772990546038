@use '../../styles/variables.scss' as vars;

.listButton {
  width: 100%;
  min-width: auto;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}
