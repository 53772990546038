@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
  height: 100%;
  width: 100vw;
  overflow: auto;
  background-color: vars.$color-bg-work-area;

  & > * {
    flex-shrink: 0;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  background-color: vars.$color-default-white;
  border-right: 1px solid vars.$color-border;
  width: 250px;
  padding: 12px;
}

.workZone {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1;
  width: calc(100vw - 250px);
}

.container {
  flex: 1 1 100%;
  overflow: auto;
}
