@media print {
  @page {
    margin: 30mm;
  }

  .printable {
    padding: 40px;
    width: 100%;
    position: relative;
  }

  .qrCode {
    &:first-child {
      page-break-before: avoid !important;
    }

    break-inside: avoid !important;
    -webkit-break-inside: avoid !important;
    page-break-inside: avoid !important;
    page-break-before: always;
    page-break-after: always;

    &:last-child {
      page-break-after: avoid !important;
    }
  }
}
