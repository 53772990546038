@use '../../styles/variables.scss' as vars;

.wrapper {
  background-color: vars.$color-bg-primary;
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper header,
.wrapper footer {
  flex-shrink: 0;
}

.wrapper main {
  flex-grow: 1;
  height: calc(100vh - #{vars.$header-height});
  overflow: auto;

  display: flex;
  flex-direction: column;
}
