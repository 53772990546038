@use '../../styles/variables.scss' as vars;

.root {
  border: 1px solid vars.$color-border;
  border-radius: vars.$border-radius-s;
  background-color: vars.$color-default-white;
  box-shadow: vars.$box-shadow-card;
  padding: 24px;
  overflow: auto;
}

.noPadding {
  padding: 0;
}
