/* padding */
@for $i from 0 through 15 {
  .p-#{$i} {
    padding: calc(var(--offset-base-size) * #{$i});
  }
  .pl-#{$i} {
    padding-left: calc(var(--offset-base-size) * #{$i});
  }
  .pr-#{$i} {
    padding-right: calc(var(--offset-base-size) * #{$i});
  }
  .pt-#{$i} {
    padding-top: calc(var(--offset-base-size) * #{$i});
  }
  .pb-#{$i} {
    padding-bottom: calc(var(--offset-base-size) * #{$i});
  }
  .m-#{$i} {
    margin: calc(var(--offset-base-size) * #{$i});
  }
  .ml-#{$i} {
    margin-left: calc(var(--offset-base-size) * #{$i});
  }
  .mr-#{$i} {
    margin-right: calc(var(--offset-base-size) * #{$i});
  }
  .mt-#{$i} {
    margin-top: calc(var(--offset-base-size) * #{$i});
  }
  .mb-#{$i} {
    margin-bottom: calc(var(--offset-base-size) * #{$i});
  }
}

@for $i from 0 through 60 {
  .w-#{$i} {
    width: calc(var(--width-unit) * #{$i});
  }
}

.m-a {
  margin: auto;
}
.ml-a {
  margin-left: auto;
}
.mr-a {
  margin-right: auto;
}
.mt-a {
  margin-top: auto;
}
.mb-a {
  margin-bottom: auto;
}
