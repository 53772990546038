@use '../../../styles/variables.scss' as vars;

.listActions {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: auto;
}

.grid {
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  color: vars.$color-focus;

  :global(.active) & {
    color: vars.$color-default-white;
  }
}

.error {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: vars.$color-danger;
}

.previewCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  box-shadow: vars.$box-shadow-card;
}
