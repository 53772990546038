@use '../../styles/variables.scss' as vars;

.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: vars.$color-default-black;
  opacity: 0;
  transition: all vars.$trans-300;
}

.visible {
  opacity: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: vars.$color-deep-black;
  opacity: 0.7;
  position: absolute;
  z-index: -1;
}

.content {
  width: 100%;
  max-width: 700px;
  position: relative;
  max-height: 100vh;
  padding-top: 50px;

  &.light {
    width: auto;
    padding: 30px;

    .closeBtn {
      width: auto;
      top: 14px;
      right: 14px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}
