@use '../../styles/variables.scss' as vars;

.root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-bg-primary;
}

.logo {
  width: 400px;
  height: 400px;
}
