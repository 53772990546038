.rdp-day_selected {
  background-color: $color-focus;
  color: $color-default-white;

  &:hover {
    background-color: $color-focus;
  }
}

.ps__rail-y,
.ps__rail-x {
  opacity: 0.7;
  z-index: 1;
}
