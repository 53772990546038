.printBtn {
  margin-top: -12px;
  margin-left: -12px;
  width: calc(100% + 24px);
}

.list {
  :global(.count) {
    font-weight: 600;
    font-size: 14px;
    left: 4px;
  }
}
