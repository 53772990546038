.root {
  display: flex;
  height: 100%;
  padding: 15px;
  position: relative;
}

.slider {
  z-index: 2;
}

.view {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
