.root {
  position: relative;

  input {
    padding-right: 40px;
    width: 150px;
  }
}

.calendar {
  position: absolute;
  top: 3px;
  right: 3px;
}

.popup {
  padding: 0;
}

.todayWrapper {
  padding: 10px;
  padding-top: 0;
  display: flex;
  justify-content: center;
}

.todayBtn {
  width: 100%;
}
