.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.panel {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background-color: rgb(171 171 145);
  border-color: rgb(163 163 152);
}

.content {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: auto;
}

.grid {
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
