@use '../../styles/variables.scss' as vars;

.root {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: vars.$border-radius-xs;
  background-color: vars.$color-primary;
  color: vars.$color-deep-black;
  display: inline-block;
  text-align: center;
  min-height: 28px;
}

.low {
  color: vars.$color-low-text;
  background-color: vars.$color-low-bg;
}

.average {
  color: vars.$color-average-text;
  background-color: vars.$color-average-bg;
}

.neutral {
  color: vars.$color-neutral-text;
  background-color: vars.$color-neutral-bg;
}

.status {
  color: vars.$color-deep-black;
  background-color: vars.$color-status-bg;
}

.bold {
  font-weight: 500;
}

.link {
  transition: all vars.$trans-300;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
