.actions {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  width: 230px;

  & > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button,
  a {
    height: 51px;
    padding: 10px 40px;
  }
}
