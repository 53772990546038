.root {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding-top: 30px;
}

.label {
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}
