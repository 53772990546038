@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(171 171 145);
  border-color: rgb(163 163 152);

  & > * {
    margin-right: 14px;

    &:last-child {
      margin-right: auto;
    }
  }
}

.search {
  flex-grow: 1;
  width: 420px;
}

.animated {
  animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    box-shadow: 0 0 20px #ff0000;
  }
  100% {
    box-shadow: 0 0 3px #b20000;
  }
}
