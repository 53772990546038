.gridRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  table {
    min-width: 100%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: 4px;
  }

  td:first-child,
  th:first-child {
    padding-left: 24px;
  }

  td:last-child,
  th:last-child {
    padding-right: 24px;
  }
}

.gridCard {
  position: relative;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridWrapper {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-bottom: 16px;
}

.message {
  margin: auto;
  padding: 12px;
}
