@use '../../styles/variables.scss' as vars;

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: vars.$color-success;
  padding: 0;
  cursor: pointer;
  transition: all vars.$trans-300;
  font-size: 20px;

  &:hover,
  &:focus {
    color: vars.$color-default-black;
  }
}

.modal {
  width: auto;
}
