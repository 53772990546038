@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  min-width: 20px;
  min-height: 20px;

  & > * {
    margin-right: 8px;
  }

  &.noLabel > input {
    margin-right: 0;
  }

  input {
    cursor: pointer;
    width: 0;
    height: 0;

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
      border: 1px solid vars.$color-border;
      border-radius: vars.$border-radius-xs;
      transition: vars.$trans-300;
      background-color: vars.$color-default-white;
    }
  }

  input:focus::after {
    border-color: vars.$color-focus;
  }

  &.checked input::after {
    background-color: vars.$color-focus;
  }

  &:not(.checked):hover input::after,
  &:not(.checked):focus-within input::after {
    border-color: vars.$color-focus;
    box-shadow: 0 0 3px 1px vars.$color-focus;
  }

  &.checked:hover input::after,
  &.checked:focus-within input::after {
    box-shadow: 0 0 3px 1px vars.$color-default-black;
  }
}

.tick {
  color: vars.$color-default-white;
  position: absolute;
  left: 5px;
  top: 6px;
  pointer-events: none;
}

.label {
  cursor: pointer;
  color: vars.$color-deep-black;
}
