$ff-default: 'Commissioner', sans-serif;
$header-height: 83px;

$color-default-black: rgb(80, 80, 90, 1);
$color-default-white: rgba(255, 255, 255, 1);
$color-deep-black: rgba(27, 29, 31, 1);

$color-danger: rgb(211, 40, 40);
$color-low-text: rgb(236, 91, 91);
$color-average-text: rgba(241, 147, 29, 1);
$color-low-bg: rgba(245, 197, 192, 1);
$color-average-bg: rgb(252, 223, 143);
$color-neutral-bg: rgb(195 249 188);
$color-neutral-text: rgb(54 197 46);
$color-status-bg: rgb(204, 234, 255);
$color-status-text: rgb(63, 148, 217);

$color-success: rgba(61, 206, 141, 1);
$color-focus: rgba(72, 138, 234, 1);
$color-border: rgba(231, 233, 240, 1);
$color-border-dark: rgb(173, 173, 174, 1);
$color-primary: rgba(238, 242, 251, 1);
$color-bg-primary: rgba(215, 215, 175, 1);
$color-bg-work-area: rgb(201, 215, 180, 1);
$color-bg-secondary: rgba(248, 251, 255, 1);
$color-transparent: rgba(255, 255, 255, 0);

$box-shadow: 0px 4px 15px 0px rgba(11, 124, 254, 0.05);
$box-shadow-card: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

$border-radius-xs: 5px;
$border-radius-s: 20px;
$border-radius-m: 26px;
$border-radius-l: 30px;

$trans-300: 0.3s ease;
