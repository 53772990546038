*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: $color-default-black;
  --offset-base-size: 4px;
  --width-unit: 10px;
}

html {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.22;
  font-family: $ff-default;
  color: $color-default-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;
  height: 100%;
}

a {
  color: $color-default-black;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// chrome search X removal
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

// ios button \ inputs reset
select,
textarea,
input:matches(
    [type='email'],
    [type='number'],
    [type='password'],
    [type='search'],
    [type='tel'],
    [type='text'],
    [type='url']
  ) {
  appearance: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none;
  user-select: none;
}

#modal-root {
  position: relative;
  z-index: 20;
}
