.mainPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.search {
  flex: 0 0 auto;
}
