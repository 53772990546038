@use '../../styles/variables.scss' as vars;

.slider {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 230px;
  padding: 8px;
  padding-right: 2px;
  position: relative;
  overflow: visible;
  background-color: rgba(171, 171, 145, 0.8);
  border-color: rgb(163 163 152);
}

.selectCount {
  padding: 6px;
  user-select: none;
  position: absolute;
  top: -12px;
  right: -12px;
  min-width: 30px;
  height: 30px;
  border-radius: 50px;
  font-weight: 500;
  background-color: vars.$color-success;
  border: 1px solid vars.$color-default-white;
  color: vars.$color-default-white;
  box-shadow: vars.$box-shadow;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.selectAll {
  position: absolute;
  top: 10px;
  left: 10px;
}

.selectionResetButton {
  border: none;
  cursor: pointer;
  background-color: vars.$color-danger;
  color: vars.$color-default-white;
  border: 1px solid vars.$color-default-white;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: -4px;
  right: -4px;
  width: 14px;
  height: 14px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerText {
  user-select: none;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  color: vars.$color-deep-black;
  font-weight: 700;
  text-align: center;
}

.secondaryText {
  padding: 0;
  margin: 0;
  user-select: none;
  font-size: 14px;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 5px;
  color: vars.$color-deep-black;
  font-weight: 500;
}

.controls {
  display: flex;
  align-items: center;
}

.swiper {
  flex-grow: 1;
  width: 100%;
  height: 400px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;

  :global(.swiper-scrollbar) {
    width: 8px;
  }

  :global(.swiper-scrollbar-drag) {
    cursor: grab;
  }
}

.sliderButton {
  background-color: vars.$color-primary;
  border: none;
  cursor: pointer;
  transition: all vars.$trans-300;
  border-radius: vars.$border-radius-xs;
  padding: 8px;
  max-width: 190px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: vars.$color-focus;
    color: vars.$color-default-white;
  }

  &Next {
    margin-bottom: 0;
    margin-top: 10px;

    svg {
      transform: rotate(180deg);
    }
  }
}

:global(.swiper-slide:nth-child(2n + 1)) {
  &.a3middle {
    .slide::before,
    .slide::after {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

:global(.swiper-slide:nth-child(2n)) {
  &.a3middle {
    margin-bottom: 20px;
    .slide::before,
    .slide::after {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
