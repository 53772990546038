@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pages {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.pageNumber {
  min-width: 50px;
  text-align: center;
}

.pageSizeSelect {
  margin-left: auto;
  margin-right: 20px;
  border: none;
  background-color: transparent;
  color: vars.$color-focus;
}
