@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
  flex: 1 0 50%;
  align-items: stretch;
  position: relative;
  padding: 15px;

  &.left {
    border-right: 2px solid vars.$color-focus;
    justify-content: flex-start;
  }

  &.rigth {
    border-left: 2px solid vars.$color-focus;
    justify-content: flex-end;
  }
}

.view {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.listSlider {
  z-index: 1;
  flex-shrink: 0;
}
