@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'Commissioner';

  font-display: swap;
  src:
    url('../fonts/Commissioner-Regular.woff2') format('woff2'),
    url('../fonts/Commissioner-Regular.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: 'Commissioner';

  font-display: swap;
  src:
    url('../fonts/Commissioner-Medium.woff2') format('woff2'),
    url('../fonts/Commissioner-Medium.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: 'Commissioner';

  font-display: swap;
  src:
    url('../fonts/Commissioner-SemiBold.woff2') format('woff2'),
    url('../fonts/Commissioner-SemiBold.woff') format('woff');
}
