.root {
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.label {
  margin-left: 20px;
  margin-bottom: 6px;
  font-size: 16px;
}
