@use '../../styles/variables.scss' as vars;

.root {
  display: flex;
}

.wrapped {
  border: 1px solid vars.$color-border;
  padding: 5px;
  border-radius: vars.$border-radius-m;
}
