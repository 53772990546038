.root {
  padding-top: 24px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  z-index: 2;

  & > * {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 20px;

  & > * {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.extra {
  margin-left: auto;
}
