@use '../../styles/variables.scss' as vars;

.root {
  font-weight: 700;
  border: none;
  background-color: #f7f7f7;
  padding-top: 8px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  transition: all vars.$trans-300;
  border-radius: vars.$border-radius-xs;
  outline: none;
  position: relative;

  &:hover,
  &:focus {
    background-color: vars.$color-status-bg;
  }

  &.active:hover,
  &.active:focus {
    cursor: auto;
    background-color: vars.$color-focus;
  }
}

.active {
  color: vars.$color-primary;
  background-color: vars.$color-focus;
}

.count {
  position: absolute;
  top: -20px;
  right: -10px;
  background-color: vars.$color-average-text;
  color: #fff;
  padding: 3px;
  font-size: 14px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  line-height: 21px;
}
