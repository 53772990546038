@use '../../styles/variables.scss' as vars;

.root {
  width: 100%;
  height: 100%;
  overflow: auto;

  img {
    user-select: none;
  }
}

.content {
  position: relative;
  width: 10000px;
  height: 10000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  transform-origin: center center;
}

.animated {
  transition: transform vars.$trans-300;
}

.loading {
  opacity: 0;
}
